@charset "UTF-8";

// pincode
.pincode{
  &_info{
    padding-bottom: 0 !important;
  }
  &_view{
    &_wrap{
      padding: 2.8rem 0 2.8rem;
      background-color: $C-primary-500;
    }
    &_title{
      @include fontStyleGroup(null, $F-size-xbig, $F-weight-bold, 32px, unset);
      padding-bottom: 1.6rem;
      text-align: center;
    }
    &_content{
      @include flexGroup(row, center, center);
      .code_box{
        width: 8.8rem;
        height: 8.6rem;
        @include fontStyleGroup(null, $F-size-max, $F-weight-bold, 45px, unset, $C-primary-500);
        @include flexGroup(row, center, center);
        background-color: $C-white;
        margin-right: 1.6rem;
        &:last-child{
          margin: 0;
        }
      }
    }
  }

  &_button{
    &_wrap{
      .keypad_button_group{
        width:100%;
        @include flexGroup(row, center, center);
        flex-wrap: wrap;
        .btn_plain_digit{
          border-right: 1px solid $C-f5f5f5;
          border-bottom: 1px solid $C-f5f5f5;
          width: 33.3%;
          height: 7.7rem;
          @include fontStyleGroup(null, $F-size-xxbig, $F-weight-bold, 40px, unset, $C-a1a3a6);
          @include flexGroup(row, center, center);
          background-color: $C-white;
          &:nth-child(3n){
            border-right: 0;
          }
          &:not(:disabled):active{
            background-color: $C-black-tr2;
          }
        }
        .btn_image_delete{
          width: 33.3%;
          height: 7.7rem;
          &:active{
            background-color: $C-black-tr2;
          }
        }
      }
    }
  }
}