@charset "UTF-8";

// parked
.parked,
.exit{
  padding-top: calc(#{$G-header-heights} + 3.2rem);
  justify-content: flex-start !important;
  &_wrap{
    width: 100%;
    @include flexGroup(col, flex-start, center);
    padding: 0 2rem;
  }

  &_title{
    padding: 0 0 3.2rem;
    @include fontStyleGroup($base-font-family, $F-size-big, $F-weight-reset, 26px, unset, $C-111111);
    text-align: center;
  }

  &_form{
    position: relative;
    @include flexGroup(col, flex-start, center);
    &_input{
      width: 32rem;
      height: 6.2rem;
      margin-bottom: 1.6rem;
      @include fontStyleGroup(null, $F-size-xbig, $F-weight-medium, 32px, unset, $C-primary-500);
      &:last-child{
        margin: 0;
      }
      &:focus{
        border: 1px solid $C-primary-500;
        color: $C-primary-500;
      }
    }
    &_last_number{
      @include position(1.6rem, 1.6rem,null,null);
      //transform: translateY(-50%);
      @include fontStyleGroup(null, $F-size-xbig, $F-weight-medium, 32px, unset, $C-111111);
      //&.type_input_active{
      //  color: $C-111111;
      //}
    }
  }

  .button_group{
    width: 100%;
    @include position(null, 0, 0, 0, 10, fixed);
    //transform: translateX(-50%);
    border-top: 1px solid $C-f5f5f5;

    .btn_basic{
      &_default{
        width: 34%;
        color: $C-a1a3a6;
      }
      &_primary{
        width: calc(100% - 34%);
      }
    }
    &.btn_absolute{
      position: absolute !important;
      left: 0;
      bottom:0;
      z-index: 11;
      transform: translateX(0);
    }
    &.btn_static{
      position: static !important;
      left: 0;
      transform: translateX(0);
    }
  }
}

.exit {

  &_form {
    padding-bottom: 9.3rem;
    &_input {
      text-align: left;
      border-radius: 6px;
      padding: 1.6rem;
      width: 32rem;
      height: 6.2rem;
      margin-bottom: 1.6rem;
      border: 1px solid $C-a1a3a6;
      @include fontStyleGroup(null, $F-size-xbig, $F-weight-medium, 32px, unset, $C-a1a3a6);

      &.type_active {
        border: 1px solid $C-primary-500;
        color: $C-primary-500;
      }
    }
  }
}