@charset "UTF-8";

// variables.scss

// ~ base- reset용 기본 변수입니다.
// $F- 폰트 관련 변수입니다.
// $C- 컬러 관련 변수입니다.
// $G- 글로벌 변수입니다.
// $M- Mixin 변수입니다.

// layout Width
$G-contents: 100%;
$G-contents-gap: 24px;
$G-heights: 100%;
$G-header-heights: 56px;
$min-width: 936px;
$layout-width: 936px;

// Base Font Style
//$base-font-family: 'SKTTmap300', 'skpgo_MM', 'Noto Sans', 'Roboto', AppleSDGothicNeo, AppleSDGothicNeo-Regular, '맑은 고딕', 'Malgun Gothic', '나눔고딕', NanumGothic, '돋움', Dotum, '굴림', Gulim, Helvetica, HelveticaNeue, sans-serif;
//$F-family-medium: 'SKTTmap500', 'skpgo_MM', 'Noto Sans', 'Roboto', AppleSDGothicNeo, AppleSDGothicNeo-Regular, '맑은 고딕', 'Malgun Gothic', '나눔고딕', NanumGothic, '돋움', Dotum, '굴림', Gulim, Helvetica, HelveticaNeue, sans-serif;
//$F-family-bold: 'SKTTmap700', 'skpgo_BM', HelveticaNeue-Bold, AppleSDGothicNeo-Bold, sans-serif;
$base-font-family: 'TMOBI', '맑은 고딕', 'Malgun Gothic', '나눔고딕', NanumGothic, '돋움', Dotum, '굴림', Gulim, Helvetica, HelveticaNeue, sans-serif;
$base-font-size: 10px;
$base-line-height: 1.3;

// Font size
$F-size-maximum: 3.6rem;
$F-size-max: 3.2rem;
$F-size-xxbig: 2.8rem;
$F-size-xlbig: 2.6rem;
$F-size-xbig: 2.4rem;
$F-size-xsbigger: 2.3rem;
$F-size-xsbig: 2.0rem;
$F-size-big: 1.8rem;
$F-size-basic: 1.7rem;
$F-size: 1.6rem;
$F-size-under: 1.5rem;
$F-size-normal: 1.4rem;
$F-size-small: 1.3rem;
$F-size-xsmall: 1.2rem;
$F-size-xxsmall: 1.1rem;
$F-size-mini: 1.0rem;
$F-size-micro: 0.8rem;

// Font weight
$F-weight-reset: normal;
$F-weight-light: 250;
$F-weight-regular: 400;
$F-weight-medium: 500;
$F-weight-bold: 700;
$F-weight-heavy: 800;

// Color
$C-bfbfbf: #bfbfbf; //기본 버튼 컬러
$C-a1a3a6: #a1a3a6;
$C-f5f5f5: #f5f5f5;
$C-d8d8d8: #d8d8d8;
$C-111111: #111111;
$C-e8eaef: #e8eaef;
$C-034ec5: #034ec5;
$C-ff46b4: #ff46b4;
$C-cc3890: #CC3890;
$C-0050CC: #0050CC;
$C-CBCCCC: #cbcccc;
$C-ACACAC: #acacac;

//TMDS 컬러 정의
$C-primary-100 : #F5F9FF;
$C-primary-200: #e0edff;
$C-primary-300: #cce0ff;
$C-primary-400: #3d8bff;
$C-primary-500: #0064ff;
$C-primary-600: #0055D1;

$C-gray-100: #f8f9fa;
$C-gray-150: #f4f5f6;
$C-gray-200: #ebedf0; //in use
$C-gray-300: #dbdfe3;
$C-gray-400: #bec5cc; //in use
$C-gray-500: #8c9299;
$C-gray-600: #6e747a;
$C-gray-700: #51565c;
$C-gray-800: #36393d;
$C-gray-900: #171819;

$C-cyan-100: #F1FBFB;
$C-cyan-200: #D7F4F3;
$C-cyan-300: #BEEDEC;
$C-cyan-400: #2DCCC6;
$C-cyan-500: #00B2B2;
$C-cyan-600: #038591;

$C-magenta-100: #FEF7FC;
$C-magenta-200: #FFE9F9;
$C-magenta-300: #FFDBF4;
$C-magenta-400: #FF70C8;
$C-magenta-500: #FA32AA;
$C-magenta-600: #C9388D;

$C-purple-100: #F9F7FF;
$C-purple-200: #EEE7FE;
$C-purple-300: #E3D8FE;
$C-purple-400: #976CFB;
$C-purple-500: #773EFA;
$C-purple-600: #5B2CC7;

$C-red-100: #FEF7F6;
$C-red-200: #E0EDFF;
$C-red-500: #F24724;
$C-red-600: #A63019;

$C-orange-100: #FFFBF5;
$C-orange-200: #FFF0D9;
$C-orange-500: #FF9000;
$C-orange-600: #B86E00;

$C-yellow-100: #FFFDF6;
$C-yellow-200: #FFF3D1;
$C-yellow-500: #FFC219;
$C-yellow-600: #D9A600;

$C-green-100: #F3FCF6;
$C-green-200: #DAF5EA;
$C-green-500: #25B856;
$C-green-600: #1B853E;

$C-evblue-100: #F4FCFE;
$C-evblue-200: #DDF5FB;
$C-evblue-500: #00B4E0;
$C-evblue-600: #008FB2;

//basic color
$C-tr: transparent; //투명
$C-white: #ffffff; //흰색
$C-black: #000000; //검정색

//rgba
$C-black-tr07: rgba(0, 0, 0, 0.07);
$C-black-tr1: rgba(0, 0, 0, 0.1);
$C-black-tr2: rgba(0, 0, 0, 0.20);
$C-black-tr35: rgba(0, 0, 0, 0.35);
$C-black-tr40: rgba(0, 0, 0, 0.4);
$C-black-tr55: rgba(0, 0, 0, 0.55);
$C-black-tr6: rgba(0, 0, 0, 0.6);
$C-black-tr68: rgba(0, 0, 0, 0.68);
$C-black-tr: rgba(0, 0, 0, 0);
$C-white-tr70: rgba(255, 255, 255, 0.7);
$C-white-tr30: rgba(255, 255, 255, 0.3);
$C-red-tr08: rgba(223, 92, 92, 0.08);

// z-index
$Z-index-1: 100;
$Z-index-2: 200;
$Z-index-10: 1000;
