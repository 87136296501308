.modal {
  &_wrap {
    @include flex;
    @include flex-justify(center);
    @include flex-align(center);
    @include position(0, 0, 0, 0, $Z-index-2, fixed);
    background-color: rgba(0, 0, 0, 0.6);
  }
  &_container {
    overflow: hidden;
    width: 360px;
    max-width: 360px;
    //margin: 0 25px;
    border-radius: 8px;
    background: $C-white;
    margin: 0 2.4rem;
  }
  &_content {
    @include flex;
    @include flex-align(center);
    min-height: 88px;
    padding: 32px 20px;
  }
  &_footer {
    @include flex;
    height: 52px;

    .btn_default {
      flex: 1;
      background: $C-gray-200;
      color: $C-gray-900;
      font-weight: $F-weight-light;
    }
    .btn_primary {
      flex: 1;
      background: $C-primary-500;
      color: $C-white;
      font-weight: $F-weight-light;

      .timer {
        display: inline-block;
        overflow: hidden;
        position: relative;
        width: 18px;
        height: 18px;
        line-height: 20px;
        vertical-align: -4px;
        text-align: center;
        font-family: Roboto sans-serif;
        font-size: $F-size-xsmall;

        &::before {
          @include position(0, 0, 0, 0);
          content: '';
          border: 1px solid $C-white;
          border-top-color: #8da9e1;
          border-radius: 50%;
          animation: rotate 1s linear infinite;
        }
      }
    }
  }
  &_alert {
    flex: 1;
    text-align: center;

    &_title {
      color: $C-gray-900;
      line-height: 2.4rem;
      font-size: $F-size-big;
      font-weight: $F-weight-light;
      word-break: break-word;
    }
    &_msg {
      margin-top: 14px;
      color: $C-gray-900;
      line-height: 2.2rem;
      font-size: $F-size-big;
    }
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}