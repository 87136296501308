@charset "UTF-8";

// settings
.settings {
  &_wrap{
    width: 100%;
    height: 100vh;
    background: $C-gray-100;
    padding: $G-header-heights 0 0;
  }
  .button_group{
    padding: 1rem 0 0;
    .type_mobile &{
      position: unset;
    }
  }
}
