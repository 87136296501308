@charset "UTF-8";

// buttons
.btn {
  &_wrap {
    text-align: center;
  }

  &_basic {
    &_default,
    &_primary {
      &:not(:disabled):active {
        background-color: $C-d8d8d8;
      }

      display: inline-block;
      width: 100%;
      height: 7.2rem;
      @include fontStyleGroup(null, $F-size-xbig, $F-weight-bold, 1.33, unset, null);
      text-align: center;
    }
    &_default {
      &.type_disabled {
        color: $C-e8eaef;
      }
      &:not(:disabled):active {
        background-color: $C-d8d8d8;
      }
      &:disabled {
        &:active {
        }
      }
      background-color: $C-white;
      color: $C-primary-500;
    }
    &_primary {
      &.type_disabled {
        background-color: $C-d8d8d8;
        color: $C-e8eaef;
        &:not(:disabled):active {
          background-color: $C-gray-100;
        }
      }
      &:not(:disabled):active {
        background-color: $C-034ec5;
      }
      &:disabled {
        &:active {
        }
      }
      background-color: $C-primary-500;
      color: $C-white;
    }
    &_inner {
      width: 100%;
    }
  }

  &_image {
    &_back {
      @include inBlind();
      @include position(0,null,null,0);
      @include use-svg(ico-header-back, 48px, 48px, 0.4rem 0.4rem, $wrap-position: null);
    }
    &_delete {
      @include position(0,null,null,0);
      @include use-svg(icon-delete, 61px, 61px, 50% 50%, block, center, $element: before);
      //@include use-svg(icon-delete, 60px, 60px, $wrap-position: null);
    }
    &_setting{
      @include inBlind();
      @include position(28px,null,null,43px);
      @include use-svg(ico_setting_solid, 32px, 32px, 0 0,  $wrap-position: absolute);
    }
  }

  &_plain {
    &_default,
    &_primary{
      display: inline-block;
      vertical-align: top;
      @include fontStyleGroup(null, $F-size-xxbig, $F-weight-bold, 38px, unset, $C-primary-500);
      padding: 2.6rem 0;

    }
    &_default {
      background-color: $C-white;
      &.type_disabled {
        background-color: $C-white;
        color: $C-e8eaef;
        &:not(:disabled):active {
          background-color: $C-gray-100;
        }
      }
    }
    &_primary{
      background-color: $C-primary-500;
      color:$C-white;
      &.type_disabled {
        background-color: $C-d8d8d8;
        color: $C-e8eaef;
        &:not(:disabled):active {
          background-color: $C-gray-100;
        }
      }
    }
  }

  &_logout{
    width: 100%;
    background: $C-white;
    padding: 2rem;
    text-align: left;
    @include fontStyleGroup(null, $F-size-big, $F-weight-light, 24px, null, $C-gray-900);
    @include use-svg(ico_arrow_right, 20px, 20px, 50% 20px, block, $wrap-position: relative, $type: right);
    &:active{
      background: rgba($C-gray-700, 0.15);
    }
    &:disabled,
    &.disabled{
    }
  }
}

.button_group{

}