@font-face {
  font-family: 'SKTTmap300';
  font-display: swap;
  src: url('../../assets/fonts/SKTTmap300.woff') format('woff'),
    url('../../assets/fonts/SKTTmap300.ttf') format('truetype');
}
@font-face {
  font-family: 'SKTTmap500';
  font-display: swap;
  src: url('../../assets/fonts/SKTTmap500.woff') format('woff'),
    url('../../assets/fonts/SKTTmap500.ttf') format('truetype');
}
@font-face {
  font-family: 'SKTTmap700';
  font-display: swap;
  src: url('../../assets/fonts/SKTTmap700.woff') format('woff'),
    url('../../assets/fonts/SKTTmap700.ttf') format('truetype');
}

@font-face {
  font-family: 'NanumSquareRoundEB';
  font-display: swap;
  src: url('../../assets/fonts/NanumSquareRoundEB.woff') format('woff'),
    url('../../assets/fonts/NanumSquareRoundEB.ttf') format('truetype');
}

@font-face {
  font-family: 'TMOBI';
  font-display: swap;
  font-weight: 300;
  src: url("../../assets/fonts/TMOBI300.woff") format('woff'),
  url("../../assets/fonts/TMOBI300.ttf") format('truetype');
}
@font-face {
  font-family: 'TMOBI';
  font-display: swap;
  font-weight: 500;
  src: url("../../assets/fonts/TMOBI500.woff") format('woff'),
  url("../../assets/fonts/TMOBI500.ttf") format('truetype');
}
@font-face {
  font-family: 'TMOBI';
  font-display: swap;
  font-weight: 700;
  src: url("../../assets/fonts/TMOBI700.woff") format('woff'),
  url("../../assets/fonts/TMOBI700.ttf") format('truetype');
}