@charset "UTF-8";

// Loading
.loading_indicator {
  @include flex;
  @include flex-justify(center);
  @include flex-align(center);
  @include position(0, 0, 0, 0, $Z-index-10, fixed);

  &.type-hide {
    display: none;
  }
}

.container{
  .contents{
   // @include flexGroup(col, center, center);
  }

  .header {
    width: $G-contents;
    @include position(0, 0, null, 50%, $Z-index-1, fixed);
    transform: translateX(-50%);
    height: $G-header-heights;
    background: $C-white;
    text-align: center;
    &_title{
      @include fontStyleGroup(null, $F-size-big, $F-weight-medium, $G-header-heights, null, $C-111111);
    }
  }
}
