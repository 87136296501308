@charset "UTF-8";

// inputs
.input_text{
  width: 100%;
  height:5.6rem;
  background: $C-white;
  border: 1px solid $C-gray-200;
  border-radius: 8px;
  padding: 1.6rem;
  @include fontStyleGroup(inherit, $F-size, $F-weight-reset, 56px, unset, $C-111111);
  outline: none;
  &::placeholder{
    color: $C-gray-400;
  }


  &:focus {
    border: 1px solid #111111;
  }
  &:disabled{
  }
  &:invalid {
  }
}