@charset "UTF-8";

// login
.login {
  padding: 0 8.4rem;
  width: 936px;
  height: 100vh;
  margin: 0 auto;
  @include flexGroup(col, center, center);

  &_wrap{
    width: 100%;
    background-color: $C-f5f5f5;
    padding: 9.6rem 0 11.3rem;
    border-radius: 8px;
    @include flexGroup(col, center, center);
  }

  &_title{
    padding: 0 0 4rem;
    @include fontStyleGroup(null, $F-size-max, $F-weight-medium, 24px, unset, $C-111111);
  }

  &_form{
    width: 100%;
    padding: 0 6.4rem;

    &_input{
      &:focus {
        border: 1px solid $C-111111;
      }
    }
  }

  .button_group{
    width: 100%;
    margin-top: 6.4rem;
    padding: 0 6.4rem;
    .btn{
      &_basic {
        &_primary {
          border-radius: 8px;
          height: 5.6rem;
          @include fontStyleGroup($base-font-family, $F-size-big, $F-weight-reset, 1.33, unset, null);
        }
      }
    }
  }
}
