@charset "UTF-8";

// main
.main {
  &.contents{
    width: 960px;
    margin: 0 auto;
    height: 100vh;
    @include flexGroup(col, center, center);
  }
  &_wrap{
    width: 100%;
    @include flexGroup(row, center, flex-start);
    border: 1px solid $C-f5f5f5;
    > .col{
      padding: 0 0 4rem;
      font-weight: $F-weight-medium;
      width: 50%;
    }
  }

  .inout_info{
    border-right: 1px solid $C-f5f5f5;
    padding:0;
    height: 100%;
    &_title{
      position: relative;
      text-align: center;
      padding: 2.8rem 0 4rem;
      border-bottom: 1px solid $C-f5f5f5;
      @include fontStyleGroup(null, $F-size-xbig, $F-weight-bold, 32px, unset, $C-primary-500)
    }

    &_list{
      .col{
        padding: 1.8rem 0;
        @include fontStyleGroup(null, $F-size-xsbig, $F-weight-medium, 28px, unset, $C-a1a3a6);
        flex:1;
        text-align: center;
      }

      &_title{
        @include flexGroup(row, center, center);
        border-bottom: 1px solid $C-f5f5f5;
      }

      &_content{
        overflow-y: scroll;
        height: 410px;
        @include flexGroup(col, flex-start, center);
        li{
          width: 100%;
          @include flexGroup(row, center, center);

          > .col{
            &:first-child{
              color: $C-111111;
            }
            &:last-child{
              color: $C-primary-500;
            }
          }
        }
      }
    }
  }

  .button_group{
    @include flexGroup(row, center, center);
    button{
      flex: 1 1 auto;
    }
    & .btn_basic_default,
    & .btn_basic_primary{
      height: 7.7rem;
      border: 0;
    }
  }
}
