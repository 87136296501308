@charset "UTF-8";

// type mobile
.type_mobile{
  .login{
    padding: 0;
    width: 100%;
    height: 100%;

    &_wrap{
      width: 100%;
      padding: 8.8rem 2rem 0;
      text-align: center;
      background: none;
    }

    &_title{
      padding-bottom: 3.2rem;
      @include fontStyleGroup(null, $F-size-xbig, $F-weight-medium, 1.33, unset, $C-111111);
    }

    &_form{
      width: 100%;
      padding:0;
    }

    .button_group{
      margin:0;
      padding:0;
      & .btn_basic_primary{
        border-radius: unset;
        height: 7.2rem;
        @include fontStyleGroup(null, $F-size-xbig, $F-weight-bold, 1.33, unset, null);
      }
    }
  }

  .button_group{
    width: 100%;
    @include position(null,null,0,0, 10, fixed);
    @include flexGroup(row, center, center);
    border-top: 1px solid $C-f5f5f5;
  }

  .btn {
    &_image {
      &_back {
        @include inBlind();
        @include position(0,null,null,0);
        @include use-svg(ico-header-back, 48px, 48px, 0.4rem 0.4rem, $wrap-position: null);
      }
      &_delete {
        @include position(0,null,null,0);
        @include use-svg(ico-delete2, 48px, 48px, 50% 50%, block, center, $element: before);
        border: 1px solid #ffffff;
      }
    }

    &_plain {
      &_default,
      &_primary{
        display: inline-block;
        vertical-align: top;
        @include fontStyleGroup(null, $F-size-xxbig, $F-weight-bold, 38px, unset, $C-primary-500);
        padding: 2.6rem 0;

      }
      &_default {
        background-color: $C-white;
        &.type_disabled {
          background-color: $C-white;
          color: $C-e8eaef;
          &:not(:disabled):active {
            background-color: $C-gray-100;
          }
        }
      }
      &_primary{
        background-color: $C-primary-500;
        color:$C-white;
        &.type_disabled {
          background-color: $C-d8d8d8;
          color: $C-e8eaef;
          &:not(:disabled):active {
            background-color: $C-gray-100;
          }
        }
      }
    }
  }

  // inputs
  .input_text{
    width: 100%;
    height: 6.2rem;
    background: $C-white;
    border: 1px solid $C-primary-500;
    border-radius: 0;
    padding: 1.5rem;
    @include fontStyleGroup(null, $F-size-xbig, $F-weight-medium, 62px, null, $C-111111);
    outline: none;
    &::placeholder{
      color: $C-a1a3a6;
    }

    &:focus {
      border: 1px solid $C-primary-500;
    }
    &:disabled{
    }
    &:invalid {
    }
  }

  .main{
    &.contents{
      width: 100%;
      display: initial;
    }
    &_wrap{
      border:0;
      display: initial;
      & .col{
        width: 100%;
      }
    }
    &_header{
      position: sticky;
      top: 0;
      left: 0;
      z-index: $Z-index-1;
      background: $C-white;

      &_title{
        padding: 1.4rem 2rem;
        @include fontStyleGroup(null, $F-size-big, $F-weight-medium, 1.56, null, $C-111111);
        & .btn_image_setting{
          top: 12px;
          left: unset;
          right: 20px;
        }
      }

      &_tab{
        @include flexGroup(row, center, center);
        height: 5.6rem;
        border-bottom: solid 2px $C-f5f5f5;
        .btn_tab{
          text-align: center;
          padding: 1.4rem 0;
          @include fontStyleGroup(null, $F-size-big, $F-weight-medium, 1.56, unset, $C-111111);
          flex: 1;
          &.active{
            color: $C-primary-500;
            border-bottom: solid 2px $C-primary-500;
          }
        }
      }
    }

    .inout_info{

      &_list{
        //overflow-y: scroll;
        //height: calc( 100vh - (56px + 56px));

        &_title{
          @include flexGroup(row, center, center);
          border-bottom: 1px solid $C-f5f5f5;
        }

        &_content{
          @include flexGroup(col, flex-start, center);
          height: auto;
          li.col{
            width: 100%;
            padding: 2.4rem 2rem;
            @include flexGroup(col, flex-start, flex-start);
            border-bottom: 8px solid $C-f5f5f5;

            & .tag{
              border-radius: 2px;
              border: solid 1px $C-primary-500;
              @include fontStyleGroup(null, $F-size-xsmall, $F-weight-medium, 28px, null,  $C-primary-500);
              padding: 0 3px;
              line-height: 2rem;
            }

            & .desc{
              @include flexGroup(row, flex-start, flex-start);
              margin-top: 0.8rem;
              & > *{
                @include fontStyleGroup($base-font-family, $F-size, null, 24px, null,  $C-111111);
              }

              &_plate_number{
                padding-left: 3.2rem;
                font-weight: $F-weight-medium;
              }
            }

          }
        }

        .no_more_content{
          padding: 3.6rem 0 6.7rem;
          background-color: $C-f5f5f5;
          text-align: center;
          @include fontStyleGroup($base-font-family, $F-size, null, 1.56, null, $C-ACACAC);
        }
      }

      .no_content{
        padding: 3.6rem 0 6.7rem;
        background-color: $C-f5f5f5;
        @include flexGroup(row, center, center);
        height: calc( 100vh - (56px + 56px));
        @include fontStyleGroup($base-font-family, $F-size, null, 1.56, null, $C-ACACAC);
      }
    }

    .pincode{
      &_info{
        width: 100%;
        @include flexGroup(col, center, center);
      }
      &_view{
        &_wrap{
          padding: 2.4rem 0 2rem;
          width: 32rem;
          background: none;
        }
        &_content{
          @include flexGroup(row, center, center);
          .code_box{
            //flex: 1 1 auto;
            width: 6.8rem;
            height: 6.2rem;
            border: 1px solid $C-primary-500;
            padding: 1.2rem 0.4rem;
            @include fontStyleGroup(null, $F-size-xbig, $F-weight-medium, 34px, unset, $C-primary-500);
            @include flexGroup(row, center, center);
            background-color: $C-white;
            margin-right: 1.7rem;
            &:last-child{
              margin: 0;
            }
          }
        }
      }

      &_button{
        &_wrap{
          width: 32rem;
          margin-bottom: 8rem;

          .keypad_button_group{
            width:100%;
            @include flexGroup(row, center, center);
            flex-wrap: wrap;
            .btn_plain_digit{
              width: 33.3%;
              border: 1px solid $C-white;
              height: 7.2rem;
              @include fontStyleGroup(null, $F-size-xbig, $F-weight-bold, 34px, unset, $C-white);
              @include flexGroup(row, center, center);
              background-color: $C-primary-500;
              &:not(:disabled):active{
                background-color: $C-0050CC;
                color: $C-CBCCCC;
              }
              &:disabled{
                background: $C-white;
              }
            }
            .btn_image_delete{
              width: 33.3%;
              height: 7.2rem;
              background-color: $C-ff46b4;
              &:active{
                background-color: $C-cc3890;
              }
            }
          }
        }
      }

    }

    .button_group{
      & .btn_basic_default,
      & .btn_basic_primary{
        height: 7.2rem;
        border: 0;
      }
    }
  }

  &.toast_wrap{
    top: unset;
    bottom: 0;
    margin: 0 2rem;
  }
  .toast{
    &_message {
      position: absolute;
      width: calc(100% - 4rem);
      left: 0;
      top: unset;
      bottom: 8rem;
      @include fontStyleGroup(null, $F-size, $F-weight-medium, 22px, null, $C-white);
      background: rgba(17, 17, 17, 0.80);
      border-radius: 5px;
      padding: 10px 33px;
      display: unset;
      &:before {
        content: unset;
      }
    }
  }
}

@media screen and (max-width: 936px) {
  //body {
  //  background-color: olive;
  //  color: white;
  //}
}