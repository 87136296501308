@charset "UTF-8";

//Toast
.toast{
  &_wrap{
    position: fixed;
    z-index: 9999999;
    top: 0;
    width: 100%;
    text-align: center;
  }
  &_message{
    position: absolute;
    width: 340px;
    left: calc(50% - 170px);
    top: 30px;
    @include fontStyleGroup(null, $F-size, $F-weight-medium, 22px, null, $C-white);
    background: rgba(54, 57, 61, 0.92);
    border-radius: 16px;
    visibility: hidden;
    padding: 2.4rem;
    @include use-svg(sign_check_1, 20px, 20px, 0, flex, $wrap-position: relative, $type: left, $position: relative);
    gap: 0 1rem;
    &.active{
      visibility: visible;
    }

    &.fade {
      &-enter,
      &-appear,
      &-exit-done {
        opacity: 0;
        transform: translate(0, 30%) !important;
        transition: all 100ms ease-out;
      }
      &_appear-active,
      &-enter-active {
        opacity: 1;
        transform: translate(0, 0) !important;
        transition: all 100ms ease-out;
      }
      &-enter-done,
      &-exit {
        opacity: 1;
        transform: translate(0, 0) !important;
        transition: all 100ms ease-out;
      }
      &-exit-active {
        opacity: 0;
        transform: translate(0, 30%) !important;
        transition: all 100ms ease-out;
      }
    }
  }
}
